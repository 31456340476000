<template>
  <div class="home-container">
    <Header index="0"/>
    <div class="container">
      <skeleton v-if="loading" type="home" />
      <template v-else>
        <!-- 主轮播和热门游戏组合区域 -->
        <div class="hero-container">
          <!-- 左侧大轮播 -->
          <div class="main-carousel">
            <el-carousel :interval="4000" height="400px">
              <el-carousel-item v-for="(item, index) of gameList.slice(0, 5)" :key="index">
                <div class="hero-slide" @click="changeGogame(item)">
                  <img :src="item.imgsrc" class="hero-image"/>
                  <div class="hero-content">
                    <div class="hero-tag">热门推荐</div>
                    <h2>{{ item.name }}</h2>
                    <div class="hero-meta">
                      <span class="price">¥{{ item.price || '198.00' }}</span>
                      <el-button type="primary" size="small">立即购买</el-button>
                    </div>
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
          <!-- 右侧热门游戏列表 -->
          <div class="hot-games-sidebar">
            <div class="sidebar-header">
              <h3>热门游戏</h3>
              <el-button type="text">更多</el-button>
            </div>
            <div class="hot-games-list">
              <div class="hot-game-item" 
                   v-for="(game, index) in gameList.slice(5, 10)" 
                   :key="index"
                   @click="changeGogame(game)">
                <img :src="game.imgsrc" :alt="game.name"/>
                <div class="hot-game-info">
                  <h4>{{ game.name }}</h4>
                  <div class="hot-game-meta">
                    <span class="price">¥{{ game.price || '198.00' }}</span>
                    <span class="rating">
                      <i class="el-icon-star-on"></i>
                      {{ game.rating || '4.5' }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 分类和快捷入口组合区域 -->
        <div class="categories-quick-access">
          <!-- 游戏分类 -->
          <div class="categories-section">
            <div class="section-header">
              <h3>游戏分类</h3>
              <el-button type="text">全部</el-button>
            </div>
            <div class="categories-grid">
              <div class="category-item" 
                   v-for="(category, index) in gameCategories" 
                   :key="index">
                <i :class="category.icon"></i>
                <div class="category-info">
                  <h4>{{ category.name }}</h4>
                  <span>{{ category.count }}款</span>
                </div>
              </div>
            </div>
          </div>
          <!-- 快捷入口 -->
          <div class="quick-access">
            <div class="quick-access-grid">
              <div class="quick-access-item" 
                   v-for="(item, index) of rightList"
                   :key="index"
                   @click="changeGoConsultant(index)">
                <img :src="item.src" :alt="item.title"/>
                <span>{{ item.title }}</span>
              </div>
            </div>
          </div>
        </div>

        <!-- 游戏推荐区域 -->
        <div class="game-recommendations">
          <div class="section-header">
            <h3>游戏推荐</h3>
            <div class="filter-tabs">
              <span class="active">最新</span>
              <span>热门</span>
              <span>特惠</span>
            </div>
            <el-button type="text">更多</el-button>
          </div>
          <div class="games-grid">
            <div class="game-card" 
                 v-for="(game, index) in gameList.slice(0, 12)" 
                 :key="index"
                 @click="changeGogame(game)">
              <div class="game-cover">
                <img :src="game.imgsrc" :alt="game.name"/>
                <div class="game-tags">
                  <span class="tag" v-if="game.discount">-{{ game.discount }}%</span>
                  <span class="tag new" v-if="game.isNew">新品</span>
                </div>
              </div>
              <div class="game-info">
                <h4>{{ game.name }}</h4>
                <div class="game-meta">
                  <div class="price-info">
                    <span class="current-price">¥{{ game.price || '198.00' }}</span>
                    <span class="original-price" v-if="game.originalPrice">¥{{ game.originalPrice }}</span>
                  </div>
                  <div class="rating">
                    <el-rate 
                      :value="game.rating || 4.5"
                      disabled 
                      :max="5"
                      :colors="['#ffd21e', '#ffd21e', '#ffd21e']">
                    </el-rate>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 资讯和活动区域 -->
        <div class="news-events-container">
          <!-- 游戏资讯 -->
          <div class="news-section">
            <div class="section-header">
              <h3>游戏资讯</h3>
              <el-button type="text" @click="$router.push('/informations')">更多</el-button>
            </div>
            <div class="news-grid">
              <div class="news-card featured" v-if="title[0]">
                <img :src="title[0].coverImage || gameList[0]?.imgsrc" :alt="title[0].title"/>
                <div class="news-content">
                  <h4>{{ title[0].title }}</h4>
                  <p>{{ title[0].text }}</p>
                  <div class="news-meta">
                    <span>{{ title[0].date || '2024-03-15' }}</span>
                    <span><i class="el-icon-view"></i> {{ title[0].views || '1,234' }}</span>
                  </div>
                </div>
              </div>
              <div class="news-list">
                <div class="news-item" 
                     v-for="(item, index) in title.slice(1, 5)" 
                     :key="index"
                     @click="changeLeftItem(item.id)">
                  <div class="news-item-content">
                    <h4>{{ item.title }}</h4>
                    <div class="news-meta">
                      <span>{{ item.date || '2024-03-15' }}</span>
                      <span><i class="el-icon-view"></i> {{ item.views || '1,234' }}</span>
                    </div>
                  </div>
                  <img :src="item.coverImage || gameList[0]?.imgsrc" :alt="item.title"/>
                </div>
              </div>
            </div>
          </div>
          <!-- 活动日历 -->
          <div class="events-section">
            <div class="section-header">
              <h3>近期活动</h3>
              <el-button type="text">全部</el-button>
            </div>
            <div class="events-list">
              <Swiperlist/>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import rig1 from "../../images/index/right1.png";
import rig2 from "../../images/index/right2.png";
import rig3 from "../../images/index/right3.png";
import Swiperlist from "@/components/Swiperlist/index";
import axios from "axios";
import {ARTICLE_API} from "@/api/article-api";
import api from "@/axios/api";
import Skeleton from '@/components/Skeleton/index.vue'

export default {
  data() {
    return {
      loading: true,
      // banner图列表
      gameList: [],


      // banner图右边列表
      bannerRight: [],

      title: [],
      // 咨询列表
      leftList: [],
      //咨询师列表
      rightList: [
        {
          title: "游戏客服",
          src: rig2,
        },
        {
          title: "充值付费",
          src: rig3,
        },
        {
          title: "数据图表",
          src: rig1,
        },
        {
          title: "新闻",
          src: rig3,
        },
      ],
    };
  },
  methods: {
    async gameget() {
      try {
        const res = await axios.get(this.$globle.allurl + "getHotgame");
        this.gameList = res.data;
        for (let i = 0; i < this.gameList.length; i++) {
          this.gameList[i].imgsrc = "https://media.st.dl.eccdnx.com/steam/apps/" + this.gameList[i].gameid + "/header.jpg";
        }
      } catch (error) {
        console.error('获取游戏列表失败:', error);
      }
    },
    async Hotarticalget() {
      try {
        const res = await api.get(ARTICLE_API.GET_HOTARTICLE);
        this.title = res.result;
      } catch (error) {
        console.error('获取文章列表失败:', error);
      }
    },

    changelink(event) {
      // window.location.href = event;
      // 新窗口打开
      window.open(event);
    },
    changeGogame(res) {
      this.$router.push({
        name: "gameDetail",
        query: {
          id: res.id,
        },
      });
    },
    changeGoConsultant1() {
      this.$router.push({
        name: "consultant",
        query: {
          index: "-1",
        },
      });
    },

    changeGoConsultant(index) {

      if (index == 0) {
        this.$router.push({
          name: "consultant",
          query: {
            index: index,
          },
        });
      } else if (index == 2) {
        this.$router.push("/charts");
      } else if (index == 1) {
        this.$router.push("/fufei");
      } else if (index == 3) {
        this.$router.push("/informations");
      }
    },

    // 跳转资讯详情页面
    changeLeftItem(index) {
      this.$router.push({
        name: "information",
        query: {
          id: index,
        },
      });
    },

    async loadData() {
      this.loading = true;
      try {
        await Promise.all([
          this.gameget(),
          this.Hotarticalget()
        ]);
      } catch (error) {
        console.error('加载数据失败:', error);
      } finally {
        this.loading = false;
      }
    }
  },
  components: {
    Swiperlist,
    Skeleton,
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style scoped>
.home-container {
  min-height: 100vh;
  background: #f4f5f7;
}

.container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 20px;
}

/* 主轮播和热门游戏组合区域 */
.hero-container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 20px;
  margin-bottom: 30px;
}

.main-carousel {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.hero-slide {
  position: relative;
  height: 100%;
  cursor: pointer;
}

.hero-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 30px;
  background: linear-gradient(transparent, rgba(0,0,0,0.8));
  color: white;
}

.hero-tag {
  display: inline-block;
  padding: 4px 12px;
  background: #ff6b6b;
  color: white;
  border-radius: 4px;
  font-size: 14px;
  margin-bottom: 10px;
}

.hero-content h2 {
  font-size: 24px;
  margin-bottom: 15px;
}

.hot-games-sidebar {
  background: white;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.hot-games-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.hot-game-item {
  display: flex;
  gap: 15px;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.hot-game-item:hover {
  background: #f8f9fa;
}

.hot-game-item img {
  width: 80px;
  height: 45px;
  object-fit: cover;
  border-radius: 4px;
}

.hot-game-info {
  flex: 1;
}

.hot-game-info h4 {
  font-size: 14px;
  margin-bottom: 5px;
}

.hot-game-meta {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
}

/* 分类和快捷入口组合区域 */
.categories-quick-access {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 20px;
  margin-bottom: 30px;
}

.categories-section {
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.categories-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 15px;
}

.category-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px;
  background: #f8f9fa;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s;
}

.category-item:hover {
  background: #e9ecef;
}

.category-item i {
  font-size: 24px;
  color: #339af0;
}

.category-info h4 {
  font-size: 14px;
  margin-bottom: 4px;
}

.category-info span {
  font-size: 12px;
  color: #868e96;
}

.quick-access {
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.quick-access-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}

.quick-access-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 15px;
  background: #f8f9fa;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s;
}

.quick-access-item:hover {
  background: #e9ecef;
}

.quick-access-item img {
  width: 40px;
  height: 40px;
  object-fit: contain;
}

/* 游戏推荐区域 */
.game-recommendations {
  background: white;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 30px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.filter-tabs {
  display: flex;
  gap: 20px;
}

.filter-tabs span {
  cursor: pointer;
  padding: 5px 0;
  color: #868e96;
}

.filter-tabs span.active {
  color: #339af0;
  border-bottom: 2px solid #339af0;
}

.games-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.game-card {
  cursor: pointer;
  transition: transform 0.3s;
}

.game-card:hover {
  transform: translateY(-5px);
}

.game-cover {
  position: relative;
  padding-top: 56.25%;
  border-radius: 8px;
  overflow: hidden;
}

.game-cover img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.game-tags {
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  gap: 5px;
}

.tag {
  padding: 4px 8px;
  background: #ff6b6b;
  color: white;
  border-radius: 4px;
  font-size: 12px;
}

.tag.new {
  background: #339af0;
}

.game-info {
  padding: 10px 0;
}

.game-info h4 {
  font-size: 14px;
  margin-bottom: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.game-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.price-info {
  display: flex;
  flex-direction: column;
}

.current-price {
  color: #ff6b6b;
  font-weight: bold;
}

.original-price {
  font-size: 12px;
  color: #868e96;
  text-decoration: line-through;
}

/* 资讯和活动区域 */
.news-events-container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 20px;
}

.news-section {
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.news-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

.news-card.featured {
  position: relative;
  height: 300px;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
}

.news-card.featured img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.news-card.featured .news-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background: linear-gradient(transparent, rgba(0,0,0,0.8));
  color: white;
}

.news-list {
  display: grid;
  gap: 15px;
}

.news-item {
  display: flex;
  gap: 15px;
  padding: 15px;
  background: #f8f9fa;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.news-item:hover {
  background: #e9ecef;
}

.news-item-content {
  flex: 1;
}

.news-item img {
  width: 100px;
  height: 60px;
  object-fit: cover;
  border-radius: 4px;
}

.news-meta {
  display: flex;
  gap: 15px;
  color: #868e96;
  font-size: 12px;
  margin-top: 8px;
}

.events-section {
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

/* 响应式设计 */
@media (max-width: 1200px) {
  .hero-container,
  .categories-quick-access,
  .news-events-container {
    grid-template-columns: 1fr;
  }
  
  .hot-games-sidebar,
  .quick-access {
    display: none;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 10px;
  }
  
  .games-grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
  
  .news-card.featured {
    height: 200px;
  }
}
</style>
